import React from "react";
import { TextField, DefaultButton } from "@fluentui/react";
import { makeStyles } from "@fluentui/react-components";
import Card from "../components/Card"; // Import the reusable Card component
import Header from "../components/Header";
import { ContactIcon } from "@fluentui/react-icons-mdl2";

const useStyles = makeStyles({
  root: {
    padding: "0 20px",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  cardContent: {
    marginTop: "20px",
    width: "100%",
  },
  field: {
    marginBottom: "12px",
  },
  button: {
    backgroundColor: "#0078d4",
    color: "#ffffff",
    width: "100%",
    padding: "12px 0",
    borderRadius: "8px",
  },
});

function AccountSettings() {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <Header showBackButton={true} />
      <Card
        icon={<ContactIcon />}
        title="Account Information"
        description={
          <div className={styles.cardContent}>
            <TextField label="Name" defaultValue="John Doe" className={styles.field} />
            <TextField label="Email" defaultValue="john.doe@example.com" className={styles.field} />
            <TextField label="Subscription" defaultValue="Premium" readOnly className={styles.field} />
            <DefaultButton text="Save Changes" className={styles.button} iconProps={{ iconName: "Save" }} />
          </div>
        }
        onClick={() => {}}
      />
    </div>
  );
}

export default AccountSettings;
