import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@fluentui/react-components";
import { BackIcon } from "@fluentui/react-icons-mdl2";
import logo from "../../../assets/logo-no-background.png";

// Styles object using Fluent UI `makeStyles`
const useStyles = makeStyles({
  headerContainer: {
    width: "100vw", // Full width of the viewport
    position: "relative", // Positioned relative to the viewport
    left: "50%", // Centering trick
    transform: "translateX(-50%)", // Centering trick
    padding: "10px 15px",
    // paddingBottom: "20px", // Adds padding to the bottom for spacing
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#ffffff",
    borderBottom: "1px solid #e0e0e0",
    boxSizing: "border-box",
    borderBottomLeftRadius: "10px", // Rounded bottom-left corner
    borderBottomRightRadius: "10px", // Rounded bottom-right corner
    marginBottom: "10px",
  },
  backButton: {
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "20px",
    color: "#0078d4", // Office blue color for consistency
  },
  logo: {
    height: "20px", // Reduced size of the logo to be less prominent
  },
  spacer: {
    width: "20px", // A spacer to balance the logo in the center
  },
});

const Header = ({ showBackButton = true }) => {
  const navigate = useNavigate();
  const styles = useStyles();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <header className={styles.headerContainer}>
      {showBackButton ? (
        <button className={styles.backButton} onClick={handleBack}>
          <BackIcon size={24} />
        </button>
      ) : (
        <div className={styles.spacer}></div>
      )}
      <img src={logo} alt="Company Logo" className={styles.logo} />
      <div className={styles.spacer}></div>
    </header>
  );
};

// Prop types validation
Header.propTypes = {
  showBackButton: PropTypes.bool,
};

export default Header;
