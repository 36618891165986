import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, Combobox, Option, SearchBox } from "@fluentui/react-components";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: "8px",
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    border: "1px solid #e0e0e0", // Light grey border
    marginBottom: "4px",
    gap: "8px", // Space between elements
    maxWidth: "400px", // Adjust for responsive design
  },
});

function RequestsSearchFilter({ searchQuery, onSearchChange, statusFilter, onStatusChange, timeFilter, onTimeChange }) {
  const styles = useStyles();
  const [selectedFilters, setSelectedFilters] = useState({
    status: statusFilter,
    time: timeFilter,
  });

  const handleSearchChange = (event, data) => {
    onSearchChange(data.value || "");
  };

  const handleFilterChange = (event, data) => {
    const newFilters = { ...selectedFilters };
    if (["All", "Completed", "Pending", "In Progress"].includes(data.optionValue)) {
      newFilters.status = data.selectedOptions.includes(data.optionValue) ? data.optionValue : "All";
      onStatusChange(event, { key: newFilters.status });
    } else {
      newFilters.time = data.selectedOptions.includes(data.optionValue) ? data.optionValue : "Last 7 Days";
      onTimeChange(event, { key: newFilters.time });
    }
    setSelectedFilters(newFilters);
  };

  return (
    <div className={styles.container}>
      <SearchBox placeholder="Search..." value={searchQuery || ""} onChange={handleSearchChange} />
      <Combobox
        multiselect
        placeholder="Select filters"
        selectedOptions={[selectedFilters.status, selectedFilters.time]}
        onOptionSelect={handleFilterChange}
      >
        <Option value="All">All</Option>
        <Option value="Completed">Completed</Option>
        <Option value="Pending">Pending</Option>
        <Option value="In Progress">In Progress</Option>
        <Option value="Last 7 Days">Last 7 Days</Option>
        <Option value="Last 30 Days">Last 30 Days</Option>
        <Option value="This Year">This Year</Option>
        <Option value="All Time">All Time</Option>
      </Combobox>
    </div>
  );
}

RequestsSearchFilter.propTypes = {
  searchQuery: PropTypes.string.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  statusFilter: PropTypes.string.isRequired,
  onStatusChange: PropTypes.func.isRequired,
  timeFilter: PropTypes.string.isRequired,
  onTimeChange: PropTypes.func.isRequired,
};

export default RequestsSearchFilter;
