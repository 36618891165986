import React from "react";
import PropTypes from "prop-types";
import Step from "./Step";

const StepsTaken = ({ steps }) => {
  return (
    <div>
      {steps.map((step) => (
        <Step key={step.id} step={step} />
      ))}
    </div>
  );
};

StepsTaken.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      details: PropTypes.arrayOf(
        PropTypes.shape({
          description: PropTypes.string.isRequired,
          time: PropTypes.string,
          link: PropTypes.shape({
            url: PropTypes.string,
            text: PropTypes.string,
          }),
        })
      ).isRequired,
    })
  ).isRequired,
};

export default StepsTaken;
