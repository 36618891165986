import React from "react";
import { Text, Icon } from "@fluentui/react";
import { makeStyles } from "@fluentui/react-components";

const useStyles = makeStyles({
  root: {
    padding: "20px",
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    marginBottom: "24px",
  },
  header: {
    fontSize: "24px",
    fontWeight: "700",
    marginBottom: "16px",
  },
  metricsContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "24px",
  },
  metricBox: {
    backgroundColor: "#f3f3f3",
    borderRadius: "8px",
    padding: "16px",
    flex: 1,
    marginRight: "16px",
    textAlign: "center",
  },
  metricValue: {
    fontSize: "32px",
    fontWeight: "700",
    marginBottom: "8px",
  },
  metricLabel: {
    fontSize: "16px",
    color: "#666",
  },
  recentActivityContainer: {
    backgroundColor: "#f3f3f3",
    borderRadius: "8px",
    padding: "16px",
  },
  recentActivityItem: {
    padding: "12px 0",
    borderBottom: "1px solid #ddd",
  },
  activityIcon: {
    marginRight: "10px",
    color: "#0078d4",
  },
});

function Dashboard() {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <Text variant="xLarge" className={styles.header}>
        Dashboard Overview
      </Text>

      <div className={styles.metricsContainer}>
        <div className={styles.metricBox}>
          <div className={styles.metricValue}>25</div>
          <div className={styles.metricLabel}>Pending Requests</div>
        </div>
        <div className={styles.metricBox}>
          <div className={styles.metricValue}>58</div>
          <div className={styles.metricLabel}>Clients Managed</div>
        </div>
        <div className={styles.metricBox}>
          <div className={styles.metricValue}>14</div>
          <div className={styles.metricLabel}>Tasks Today</div>
        </div>
      </div>

      <div className={styles.recentActivityContainer}>
        <Text variant="large" className={styles.header}>
          Recent Activity
        </Text>
        <div className={styles.recentActivityItem}>
          <Icon iconName="Mail" className={styles.activityIcon} />
          <span>Responded to client inquiry from Jane Doe</span>
        </div>
        <div className={styles.recentActivityItem}>
          <Icon iconName="TaskLogo" className={styles.activityIcon} />
          <span>Completed tax filing for Doe Industries</span>
        </div>
        <div className={styles.recentActivityItem}>
          <Icon iconName="Calendar" className={styles.activityIcon} />
          <span>Scheduled meeting with Acme Inc. for budget review</span>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
