import React from "react";
import PropTypes from "prop-types";
import { Text } from "@fluentui/react";
import { makeStyles } from "@fluentui/react-components";
import { formatDistanceToNow } from "date-fns";
import { useNavigate } from "react-router-dom";
import { Badge } from "@fluentui/react-components";
import { CheckmarkRegular, BrainCircuitRegular, PenSparkleRegular } from "@fluentui/react-icons";

const useStyles = makeStyles({
  card: {
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    border: "1px solid #e0e0e0", // Light grey border
    padding: "12px 16px",
    margin: "8px 0",
    cursor: "pointer",
    transition: "transform 0.2s, box-shadow 0.2s",
    "&:hover": {
      transform: "translateY(-2px)",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.15)",
    },
    position: "relative", // For positioning the badge
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minWidth: "200px",
  },
  cardHeader: {
    fontSize: "16px",
    fontWeight: "600",
    color: "#333",
    marginBottom: "8px",
    wordWrap: "break-word", // Ensure text wraps
    whiteSpace: "pre-wrap", // Handle wrapping correctly
    marginRight: "40px", // Ensure space for the badge
  },
  divider: {
    borderTop: "1px solid #ddd",
    margin: "8px 0",
  },
  clientName: {
    fontSize: "14px",
    color: "#333",
    marginBottom: "4px",
  },
  cardEmail: {
    fontSize: "14px",
    color: "#0078d4", // Microsoft blue for links
    marginBottom: "12px",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  cardFooter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "12px",
    color: "#666",
  },
  timeAgo: {
    color: "#999",
  },
  statusBadge: {
    position: "absolute",
    top: "8px",
    right: "8px",
    fontSize: "12px",
  },
});

function RequestsCard({ id, summary, client, email, date, status }) {
  const styles = useStyles();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/request/${id}`);
  };

  // Map status to Badge properties
  const statusBadgeMap = {
    Completed: {
      icon: <CheckmarkRegular />,
      color: "#4CAF50", // Green
    },
    "In Progress": {
      icon: <BrainCircuitRegular />,
      color: "#FFC107", // Yellow
    },
    Pending: {
      icon: <PenSparkleRegular />,
      color: "brand", // Outlook Blue
    },
  };

  const badge = statusBadgeMap[status] || statusBadgeMap.Pending;

  return (
    <div className={styles.card} onClick={handleClick}>
      <Badge
        className={styles.statusBadge}
        size="medium"
        icon={badge.icon}
        style={{ backgroundColor: badge.color, color: "#fff" }} // Ensure the icon color is white
      />
      <Text className={styles.cardHeader}>{summary}</Text>
      <div className={styles.divider}></div>
      <Text className={styles.clientName}>{client}</Text>
      <a href={`mailto:${email}`} className={styles.cardEmail}>
        {email}
      </a>
      <div className={styles.cardFooter}>
        <Text className={styles.timeAgo}>{formatDistanceToNow(new Date(date))} ago</Text>
      </div>
    </div>
  );
}

RequestsCard.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  summary: PropTypes.string.isRequired,
  client: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired, // Ensure status is required
};

export default RequestsCard;
