import React from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@fluentui/react-components";
import { SettingsIcon, ContactIcon, TaskManagerIcon } from "@fluentui/react-icons-mdl2";
import Card from "../components/Card"; // Ensure this is the same Card component used in Home
import Header from "../components/Header";

const useStyles = makeStyles({
  root: {
    padding: "0 20px",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  headerContainer: {
    width: "100%",
    maxWidth: "100vw",
    padding: "0 20px",
    boxSizing: "border-box",
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center", // Center align the cards horizontally
    gap: "12px",
    width: "100%",
    maxWidth: "400px",
    marginTop: "20px", // Optional: add some spacing from the header
  },
});

function Settings() {
  const styles = useStyles();
  const navigate = useNavigate();

  const settingsOptions = [
    {
      title: "Account",
      icon: <ContactIcon />,
      description: "Manage your account settings.",
      route: "/settings/account",
    },
    {
      title: "General Settings",
      icon: <SettingsIcon />,
      description: "Adjust general preferences.",
      route: "/settings/general",
    },
    {
      title: "AI Settings",
      icon: <TaskManagerIcon />,
      description: "Configure AI-related settings.",
      route: "/settings/ai",
    },
  ];

  return (
    <div className={styles.root}>
      <div className={styles.headerContainer}>
        <Header showBackButton={true} />
      </div>
      <div className={styles.cardContainer}>
        {settingsOptions.map((option, index) => (
          <Card
            key={index}
            icon={option.icon}
            title={option.title}
            description={option.description}
            onClick={() => navigate(option.route)}
          />
        ))}
      </div>
    </div>
  );
}

export default Settings;
