import React, { useState } from "react";
import PropTypes from "prop-types";
import { Popover, PopoverTrigger, PopoverSurface, Button, Textarea } from "@fluentui/react-components";
import { Avatar, makeStyles } from "@fluentui/react-components";

const useStyles = makeStyles({
  popoverSurface: {
    maxWidth: "400px", // Limit the width for better layout inside the popover
    padding: "16px",
    borderRadius: "12px",
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  chatContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    overflowY: "auto",
    maxHeight: "300px", // Limit height and make it scrollable
  },
  userMessage: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    alignSelf: "flex-end",
  },
  aiMessage: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    alignSelf: "flex-start",
  },
  avatar: {
    width: "32px",
    height: "32px",
  },
  messageBubble: {
    padding: "10px 15px",
    borderRadius: "15px",
    maxWidth: "250px",
    wordWrap: "break-word",
    backgroundColor: "#e0e0e0",
  },
  aiMessageBubble: {
    backgroundColor: "#0078d4",
    color: "#ffffff",
  },
  inputContainer: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  instructButton: {
    width: "100%", // Make the button take up the full width
    backgroundColor: "#0078d4", // Microsoft blue background
    color: "#ffffff", // White text color
  },
  instructButtonText: {
    color: "#0078d4", // Microsoft blue for button text
  },
});

const AIInstructionsPopover = ({ onSubmit }) => {
  const styles = useStyles();
  const [message, setMessage] = useState("");
  const [chat, setChat] = useState([]);

  const handleSubmit = () => {
    if (message.trim() !== "") {
      setChat((prevChat) => [
        ...prevChat,
        { type: "user", text: message },
        { type: "ai", text: "Processing your request..." },
      ]);
      setMessage("");
      onSubmit(message);
    }
  };

  return (
    <Popover>
      <PopoverTrigger>
        <Button className={styles.instructButton}>Instruct Fiscus</Button>
      </PopoverTrigger>
      <PopoverSurface className={styles.popoverSurface}>
        <div className={styles.chatContainer}>
          {chat.map((msg, index) => (
            <div key={index} className={msg.type === "user" ? styles.userMessage : styles.aiMessage}>
              <Avatar className={styles.avatar} name={msg.type === "user" ? "User" : "Fiscus AI"} />
              <div className={`${styles.messageBubble} ${msg.type === "ai" ? styles.aiMessageBubble : ""}`}>
                {msg.text}
              </div>
            </div>
          ))}
        </div>
        <div className={styles.inputContainer}>
          <Textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Type your message here..."
            resize="vertical" // Allow vertical resizing
          />
          <Button onClick={handleSubmit} appearance="primary" className={styles.instructButtonText}>
            Send
          </Button>
        </div>
      </PopoverSurface>
    </Popover>
  );
};

AIInstructionsPopover.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default AIInstructionsPopover;
