import React from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@fluentui/react-components";
import {
  // HomeIcon,
  TaskManagerIcon,
  ContactIcon,
  // GroupIcon,
  PlugDisconnectedIcon,
  SettingsIcon,
} from "@fluentui/react-icons-mdl2";
import Card from "../components/Card";
import Header from "../components/Header";

const useStyles = makeStyles({
  root: {
    // padding: "0 0 0 20px",
    // backgroundColor: "#f3f3f3",
    minHeight: "100vh",
    fontFamily: "Segoe UI, Tahoma, Geneva, Verdana, sans-serif",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  cardContainer: {
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap",
    width: "100%",
    maxWidth: "100vh",
  },
});

function Home() {
  const styles = useStyles();
  const navigate = useNavigate();

  const cards = [
    // {
    //   icon: <HomeIcon />,
    //   title: "Dashboard",
    //   description: "View your overall activity and performance.",
    //   route: "/dashboard",
    // },
    {
      icon: <TaskManagerIcon />,
      title: "Tasks",
      description: "Manage any tasks assigned to Fiscus.",
      route: "/requests",
    },
    {
      icon: <ContactIcon />,
      title: "Contacts",
      description: "View tasks assigned by contact.",
      route: "/contacts",
    },
    // {
    //   icon: <GroupIcon />,
    //   title: "Teams",
    //   description: "Organize and collaborate with your teams.",
    //   route: "/teams",
    // },
    {
      icon: <PlugDisconnectedIcon />,
      title: "Connectors",
      description: "Connect more systems to Fiscus.",
      route: "/connectors",
    },
    {
      icon: <SettingsIcon />,
      title: "Settings",
      description: "Update your preferences and settings.",
      route: "/settings",
    },
  ];

  return (
    <div className={styles.root}>
      <Header showBackButton={false} />
      <div className={styles.cardContainer}>
        {cards.map((card, index) => (
          <Card
            key={index}
            icon={card.icon}
            title={card.title}
            description={card.description}
            onClick={() => navigate(card.route)}
          />
        ))}
      </div>
    </div>
  );
}

export default Home;
