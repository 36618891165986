import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Text, Link, ProgressIndicator } from "@fluentui/react";
import { CounterBadge, makeStyles } from "@fluentui/react-components";

const useStyles = makeStyles({
  section: {
    marginBottom: "24px",
    padding: "16px",
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    border: "1px solid #e0e0e0", // Light grey border
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Subtle shadow
  },
  stepHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
  },
  stepHeaderText: {
    fontSize: "16px",
    fontWeight: "500", // Reduced font weight
    color: "#333",
    display: "flex",
    alignItems: "center",
  },
  stepBody: {
    paddingTop: "12px",
  },
  stepItem: {
    display: "flex",
    alignItems: "flex-start",
    padding: "8px 0",
  },
  stepIconContainer: {
    marginRight: "12px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  stepTextContainer: {
    flex: 1,
    fontSize: "14px",
  },
  stepText: {
    fontSize: "14px",
    fontWeight: "400", // Reduced font weight
  },
  stepTimeContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: "4px",
  },
  stepTime: {
    color: "#666",
  },
  stepLink: {
    marginLeft: "8px",
  },
  chevron: {
    marginRight: "8px",
    width: "0",
    height: "0",
    borderLeft: "6px solid transparent",
    borderRight: "6px solid transparent",
    borderTop: "6px solid #0078d4",
    transition: "transform 0.3s ease",
  },
  chevronDown: {
    transform: "rotate(180deg)",
  },
});

const Step = ({ step }) => {
  const styles = useStyles();
  const [isOpen, setIsOpen] = useState(step.status === "In Progress");

  useEffect(() => {
    if (step.status === "In Progress") {
      setIsOpen(true);
    }
  }, [step.status]);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={styles.section}>
      <div className={styles.stepHeader} onClick={toggleCollapse}>
        <div className={styles.stepHeaderText}>
          <span className={`${styles.chevron} ${isOpen ? styles.chevronDown : ""}`} />
          {step.name}
        </div>
        <div>
          {step.status === "Completed" ? (
            <CounterBadge count="✔" color="brand" /> // Blue checkmark badge
          ) : (
            <ProgressIndicator description={step.status === "In Progress" ? "In Progress..." : ""} />
          )}
        </div>
      </div>
      {isOpen && (
        <div className={styles.stepBody}>
          {step.details
            .filter((detail) => detail.visible) // Only show visible details
            .map((detail, index) => (
              <div key={detail.id || index} className={styles.stepItem}>
                <div className={styles.stepIconContainer}>
                  <CounterBadge count={index + 1} color="brand" />
                </div>
                <div className={styles.stepTextContainer}>
                  <Text className={styles.stepText}>{detail.description}</Text>
                  <div className={styles.stepTimeContainer}>
                    {detail.time && <Text className={styles.stepTime}>{detail.time}</Text>}
                    {detail.link && (
                      <Link href={detail.link.url} className={styles.stepLink}>
                        {detail.link.text}
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

Step.propTypes = {
  step: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    details: PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.string.isRequired,
        time: PropTypes.string,
        visible: PropTypes.bool.isRequired, // Ensure visibility flag is included
        link: PropTypes.shape({
          url: PropTypes.string,
          text: PropTypes.string,
        }),
      })
    ).isRequired,
  }).isRequired,
};

export default Step;
