/* global Office console */

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export function useOfficeContext() {
  const navigate = useNavigate();

  useEffect(() => {
    Office.onReady(async (info) => {
      if (info.host === Office.HostType.Outlook) {
        const item = Office.context.mailbox.item;

        if (item) {
          // Check if the item is in read mode (MessageRead interface)
          if (item.itemType === Office.MailboxEnums.ItemType.Message && item.dateTimeCreated) {
            navigate("/request/3");
          } else {
            console.log("Compose mode or unsupported item type.");
            // Handle compose mode or unsupported item type if needed
          }
        } else {
          console.error("Office.context.mailbox.item is null.");
        }
      } else {
        console.error("Office context is not ready.");
      }
    });
  }, [navigate]);
}

// Function to insert text into the compose area of an email
export async function insertText(text) {
  try {
    Office.context.mailbox.item?.body.setSelectedDataAsync(
      text,
      { coercionType: Office.CoercionType.Text },
      (asyncResult) => {
        if (asyncResult.status === Office.AsyncResultStatus.Failed) {
          throw asyncResult.error.message;
        }
      }
    );
  } catch (error) {
    console.log("Error: " + error);
  }
}

// Function to read email metadata and body content
export async function readEmail() {
  const item = Office.context.mailbox.item;

  const emailData = {
    subject: item.subject || "",
    from: item.from ? item.from.displayName : "",
    to: item.to ? item.to.map((recipient) => recipient.displayName).join(", ") : "",
    cc: item.cc ? item.cc.map((recipient) => recipient.displayName).join(", ") : "",
    body: await getBody(),
    dateTimeCreated: item.dateTimeCreated || "",
    dateTimeModified: item.dateTimeModified || "",
  };

  console.log("Email Data:", emailData);
  return emailData;
}

async function getBody() {
  return new Promise((resolve, reject) => {
    Office.context.mailbox.item.body.getAsync(Office.CoercionType.Text, (result) => {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        resolve(result.value);
      } else {
        reject(result.error.message);
      }
    });
  });
}

// Function to create a draft email
export async function createDraft(subject, to, body) {
  Office.context.mailbox.displayNewMessageForm({
    toRecipients: to,
    subject: subject,
    body: body,
  });
}

// Function to open a dialog or navigate to a specific route (e.g., /request/1)
// Office.onReady(async (info) => {
//   if (info.host === Office.HostType.Outlook) {
//     // Open the app and pass a query parameter indicating it was opened from an email
//     Office.context.ui.displayDialogAsync(
//       "https://app.fiscusflows.com/index.html?fromEmail=true", // Adding a query parameter
//       { width: 50, height: 50 },
//       function (asyncResult) {
//         if (asyncResult.status === Office.AsyncResultStatus.Failed) {
//           console.error("Failed to open dialog:", asyncResult.error.message);
//         } else {
//           const dialog = asyncResult.value;
//           dialog.addEventHandler(Office.EventType.DialogMessageReceived, onMessageFromDialog);
//         }
//       }
//     );
//   }
// });

// function onMessageFromDialog(message) {
//   console.log("Message received from dialog:", message);
// }
