/* global console */

import React, { useState } from "react";
import { Text, DefaultButton } from "@fluentui/react";
import { makeStyles, PresenceBadge, Avatar } from "@fluentui/react-components";
import Header from "../components/Header";
import GenericSearchAndFilter from "../components/GenericSearchAndFilter";
import Card from "../components/Card"; // Import the reusable Card component
import OutlookLogo from "../../../assets/outlook.png";
import GoogleCalendarLogo from "../../../assets/google-calendar.png";
import DropboxLogo from "../../../assets/dropbox.png";

const useStyles = makeStyles({
  root: {
    padding: "0 16px",
    minHeight: "100vh",
    fontFamily: "Segoe UI, Tahoma, Geneva, Verdana, sans-serif",
  },
  integrationSection: {
    marginBottom: "8px", // Reduced margin at the bottom
  },
  sectionTitle: {
    fontSize: "20px",
    fontWeight: "600",
    marginBottom: "8px",
  },
  cardContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "12px",
    // Removed padding to allow content to be evenly spaced within the card
  },
  integrationInfo: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
  },
  integrationName: {
    fontSize: "16px", // Reduced font size to prevent overflow
    fontWeight: "500",
  },
  button: {
    backgroundColor: "#0078d4",
    color: "#ffffff",
    minWidth: "100px",
  },
  sectionSeparator: {
    margin: "12px 0 8px", // Reduced margin between sections
    borderBottom: "2px solid #ddd",
  },
  filterSection: {
    marginBottom: "16px", // Reduced spacing between filter and Connected section
  },
  cardContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "16px",
    flexWrap: "wrap",
    marginTop: "16px",
  },
});

const connectorsData = [
  {
    id: 1,
    name: "Outlook Email",
    logo: OutlookLogo,
    connected: true,
    category: "Email",
  },
  {
    id: 2,
    name: "Google Calendar",
    logo: GoogleCalendarLogo,
    connected: false,
    category: "Calendar",
  },
  {
    id: 3,
    name: "Dropbox",
    logo: DropboxLogo,
    connected: true,
    category: "Document Storage",
  },
];

function Connectors() {
  const styles = useStyles();
  const [searchQuery, setSearchQuery] = useState("");
  const [filters, setFilters] = useState({ category: "All", status: "All" });

  const handleSearchChange = (query) => {
    setSearchQuery(query);
  };

  const handleFilterChange = (event, newFilters) => {
    setFilters(newFilters);
  };

  const filterOptions = [
    { id: "email", key: "category", value: "Email", label: "Email" },
    { id: "calendar", key: "category", value: "Calendar", label: "Calendar" },
    { id: "documentStorage", key: "category", value: "Document Storage", label: "Document Storage" },
    { id: "connected", key: "status", value: "Connected", label: "Connected" },
    { id: "disconnected", key: "status", value: "Disconnected", label: "Disconnected" },
  ];

  const getFilteredConnectors = () => {
    return connectorsData
      .filter((connector) => {
        return (
          (filters.category === "All" || connector.category === filters.category) &&
          (filters.status === "All" ||
            (filters.status === "Connected" && connector.connected) ||
            (filters.status === "Disconnected" && !connector.connected)) &&
          (connector.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            connector.category.toLowerCase().includes(searchQuery.toLowerCase()))
        );
      })
      .sort((a, b) => a.name.localeCompare(b.name)); // Sort alphabetically
  };

  const connectedConnectors = getFilteredConnectors().filter((connector) => connector.connected);
  const disconnectedConnectors = getFilteredConnectors().filter((connector) => !connector.connected);

  return (
    <div className={styles.root}>
      <Header showBackButton={true} />
      <div className={styles.filterSection}>
        <GenericSearchAndFilter
          searchQuery={searchQuery}
          onSearchChange={handleSearchChange}
          filters={filters}
          onFilterChange={handleFilterChange}
          filterOptions={filterOptions}
          placeholder="Select filters" // Placeholder is now static
        />
      </div>

      {connectedConnectors.length > 0 && (
        <div className={styles.integrationSection}>
          <Text className={styles.sectionTitle}>Connected</Text>
          {connectedConnectors.map((connector) => (
            <Card
              key={connector.id}
              icon={<PresenceBadge status="available" />}
              title={connector.name}
              description={
                <div className={styles.cardContent}>
                  <div className={styles.integrationInfo}>
                    <Avatar image={{ src: connector.logo }} shape="square" size={40} alt={`${connector.name} logo`} />
                  </div>
                  <DefaultButton
                    text="Disconnect"
                    className={styles.button}
                    onClick={() => console.log(`${connector.name} disconnected`)}
                  />
                </div>
              }
              onClick={() => {}}
            />
          ))}
        </div>
      )}

      {disconnectedConnectors.length > 0 && (
        <>
          <div className={styles.sectionSeparator}></div>
          <div className={styles.integrationSection}>
            <Text className={styles.sectionTitle}>Disconnected</Text>
            {disconnectedConnectors.map((connector) => (
              <Card
                key={connector.id}
                icon={<PresenceBadge status="offline" />}
                title={connector.name}
                description={
                  <div className={styles.cardContent}>
                    <div className={styles.integrationInfo}>
                      <Avatar image={{ src: connector.logo }} shape="square" size={40} alt={`${connector.name} logo`} />
                    </div>
                    <DefaultButton
                      text="Connect"
                      className={styles.button}
                      onClick={() => console.log(`${connector.name} connected`)}
                    />
                  </div>
                }
                onClick={() => {}}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default Connectors;
