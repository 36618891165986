import React from "react";
import { Slider, DefaultButton, Dropdown } from "@fluentui/react";
import { makeStyles } from "@fluentui/react-components";
import Card from "../components/Card"; // Import the reusable Card component
import Header from "../components/Header";
import { TaskManagerIcon } from "@fluentui/react-icons-mdl2";

const useStyles = makeStyles({
  root: {
    padding: "0 20px",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  cardContent: {
    marginTop: "20px",
    width: "100%",
  },
  field: {
    marginBottom: "16px",
  },
  button: {
    backgroundColor: "#0078d4",
    color: "#ffffff",
    width: "100%",
    padding: "12px 0",
    borderRadius: "8px",
  },
});

function AISettings() {
  const styles = useStyles();

  const toneOptions = [
    { key: "friendly", text: "Friendly" },
    { key: "professional", text: "Professional" },
    { key: "informal", text: "Informal" },
  ];

  return (
    <div className={styles.root}>
      <Header showBackButton={true} />
      <Card
        icon={<TaskManagerIcon />}
        title="AI Settings"
        description={
          <div className={styles.cardContent}>
            <Dropdown
              label="Response Tone"
              options={toneOptions}
              placeholder="Select a tone"
              className={styles.field}
            />
            <Slider label="Response Length" min={1} max={10} defaultValue={5} className={styles.field} />
            <DefaultButton text="Save Changes" className={styles.button} />
          </div>
        }
        onClick={() => {}}
      />
    </div>
  );
}

export default AISettings;
