import React from "react";
import { MemoryRouter as Router, Route, Routes } from "react-router-dom";
import { useOfficeContext } from "../taskpane"; // Import the custom hook
import Dashboard from "./Dashboard";
import Requests from "./Requests";
import Contacts from "../pages/Contacts";
import Teams from "./Teams";
import Connectors from "./Connectors";
import Settings from "./Settings";
import AccountSettings from "./AccountSettings";
import GeneralSettings from "./GeneralSettings";
import AISettings from "./AISettings";
import Request from "./Request";
import Home from "./Home"; // The Menu as the homepage
import SignIn from "./SignIn"; // Placeholder for the sign-in view

function AppRoutes() {
  useOfficeContext(); // Use the custom hook here

  const isAuthenticated = true; // Assume the user is authenticated

  return (
    <Routes>
      {!isAuthenticated ? (
        <Route path="*" element={<SignIn />} /> // Redirect all paths to SignIn if not authenticated
      ) : (
        <>
          <Route path="/" element={<Home />} /> {/* Homepage showing the Menu */}
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/requests" element={<Requests />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/teams" element={<Teams />} />
          <Route path="/connectors" element={<Connectors />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/settings/account" element={<AccountSettings />} />
          <Route path="/settings/general" element={<GeneralSettings />} />
          <Route path="/settings/ai" element={<AISettings />} />
          <Route path="/request/:id" element={<Request />} /> {/* Handle dynamic request ID */}
        </>
      )}
    </Routes>
  );
}

export default function App() {
  return (
    <Router>
      <AppRoutes />
    </Router>
  );
}
