import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, Combobox, Option, SearchBox } from "@fluentui/react-components";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: "8px",
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    border: "1px solid #e0e0e0", // Light grey border
    marginBottom: "4px",
    gap: "8px",
    maxWidth: "400px",
  },
});

function ContactsSearchFilter({ searchQuery, onSearchChange, statusFilter, onStatusChange }) {
  const styles = useStyles();
  const [selectedFilters, setSelectedFilters] = useState({
    status: statusFilter,
  });

  const handleSearchChange = (event, data) => {
    onSearchChange(data.value || "");
  };

  const handleFilterChange = (event, data) => {
    const newFilters = { ...selectedFilters };
    if (["All", "Has Tasks", "No Tasks"].includes(data.optionValue)) {
      newFilters.status = data.selectedOptions.includes(data.optionValue) ? data.optionValue : "All";
      onStatusChange(event, { key: newFilters.status });
    }
    setSelectedFilters(newFilters);
  };

  return (
    <div className={styles.container}>
      <SearchBox placeholder="Search..." value={searchQuery || ""} onChange={handleSearchChange} />
      <Combobox
        multiselect
        placeholder="Select filters"
        selectedOptions={[selectedFilters.status]}
        onOptionSelect={handleFilterChange}
      >
        <Option value="All">All</Option>
        <Option value="Has Tasks">Has Tasks</Option>
        <Option value="No Tasks">No Tasks</Option>
      </Combobox>
    </div>
  );
}

ContactsSearchFilter.propTypes = {
  searchQuery: PropTypes.string.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  statusFilter: PropTypes.string.isRequired,
  onStatusChange: PropTypes.func.isRequired,
};

export default ContactsSearchFilter;
