import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import ContactsSearchFilter from "../components/ContactsSearchFilter";
import ContactsCard from "../components/ContactsCard";
import mockData from "../mockData"; // Updated import for single object
import { makeStyles } from "@fluentui/react-components";

const useStyles = makeStyles({
  container: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    padding: "0 16px",
  },
  content: {
    flexGrow: 1,
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
});

function Contacts() {
  const styles = useStyles();
  const [searchQuery, setSearchQuery] = useState("");
  const [statusFilter, setStatusFilter] = useState("All");
  const [filteredItems, setFilteredItems] = useState([]);

  useEffect(() => {
    setFilteredItems(mockData); // Set initial data
  }, []);

  useEffect(() => {
    let updatedItems = mockData.map((data) => ({
      ...data.client, // Extract client info
      tasks: mockData.filter((item) => item.client.id === data.client.id),
    }));

    if (searchQuery) {
      const lowercasedQuery = searchQuery.toLowerCase();
      updatedItems = updatedItems.filter(
        (item) =>
          item.name.toLowerCase().includes(lowercasedQuery) ||
          item.company.toLowerCase().includes(lowercasedQuery) ||
          item.email.toLowerCase().includes(lowercasedQuery)
      );
    }

    if (statusFilter !== "All") {
      updatedItems = updatedItems.filter((item) => {
        return statusFilter === "Has Tasks" ? item.tasks.length > 0 : item.tasks.length === 0;
      });
    }

    setFilteredItems(updatedItems);
  }, [searchQuery, statusFilter]);

  return (
    <div className={styles.container}>
      <Header showBackButton={true} />
      <div className={styles.content}>
        <ContactsSearchFilter
          searchQuery={searchQuery}
          onSearchChange={(value) => setSearchQuery(value || "")}
          statusFilter={statusFilter}
          onStatusChange={(e, option) => setStatusFilter(option.key)}
        />
        {filteredItems.map((contact) => (
          <ContactsCard
            key={contact.id}
            id={contact.id}
            name={contact.name}
            email={contact.email}
            company={contact.company}
            taskCount={contact.tasks.length}
            tasks={contact.tasks} // Pass the list of tasks to the ContactsCard
          />
        ))}
      </div>
    </div>
  );
}

export default Contacts;
