// components/Card.jsx
import React from "react";
import PropTypes from "prop-types";
import { Text } from "@fluentui/react";
import { makeStyles } from "@fluentui/react-components";

const useStyles = makeStyles({
  card: {
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    border: "1px solid #e0e0e0", // Light grey border
    padding: "20px",
    margin: "10px",
    width: "calc(50% - 20px)",
    minWidth: "250px",
    cursor: "pointer",
    transition: "transform 0.2s",
    "&:hover": {
      transform: "translateY(-4px)",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.15)",
    },
  },
  cardHeader: {
    display: "flex",
    alignItems: "center",
    marginBottom: "12px",
  },
  cardIcon: {
    marginRight: "12px",
    fontSize: "32px",
    color: "#0078d4", // Ensure the icon has the correct color
  },
  cardTitle: {
    fontSize: "18px",
    fontWeight: "600",
    color: "#333",
  },
  cardDescription: {
    fontSize: "14px",
    color: "#666",
  },
});

function Card({ icon, title, description, onClick }) {
  const styles = useStyles();

  return (
    <div className={styles.card} onClick={onClick}>
      <div className={styles.cardHeader}>
        <div className={styles.cardIcon}>{icon}</div> {/* Apply icon styles here */}
        <Text className={styles.cardTitle}>{title}</Text>
      </div>
      <div className={styles.cardDescription}>
        {typeof description === "string" ? <Text>{description}</Text> : description}
      </div>
    </div>
  );
}

Card.propTypes = {
  icon: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired, // Allow string or JSX
  onClick: PropTypes.func.isRequired,
};

export default Card;
