import React, { useState } from "react";
import { TextField, DefaultButton, Text } from "@fluentui/react";
import { makeStyles } from "@fluentui/react-components";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    // backgroundColor: "#f3f3f3",
  },
  form: {
    backgroundColor: "#ffffff",
    padding: "24px",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    width: "300px",
  },
  title: {
    fontSize: "24px",
    fontWeight: "600",
    marginBottom: "24px",
    textAlign: "center",
    color: "#333",
  },
  input: {
    marginBottom: "16px",
  },
  button: {
    width: "100%",
    backgroundColor: "#0078d4",
    color: "#ffffff",
  },
  error: {
    color: "red",
    marginTop: "16px",
    textAlign: "center",
  },
});

function SignIn() {
  const styles = useStyles();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleSignIn = () => {
    // Basic validation
    if (!email || !password) {
      setError("Please enter both email and password.");
      return;
    }

    // Mock authentication process
    if (email === "user@example.com" && password === "password") {
      // Navigate to the homepage (or any other route) after sign-in
      navigate("/dashboard");
    } else {
      setError("Invalid email or password.");
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <Text className={styles.title}>Sign In</Text>
        <TextField
          className={styles.input}
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
        />
        <TextField
          className={styles.input}
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Enter your password"
        />
        <DefaultButton text="Sign In" onClick={handleSignIn} className={styles.button} />
        {error && <Text className={styles.error}>{error}</Text>}
      </div>
    </div>
  );
}

export default SignIn;
