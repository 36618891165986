import * as React from "react";
import { createRoot } from "react-dom/client";
import App from "./pages/App";
import { FluentProvider, webLightTheme } from "@fluentui/react-components";

/* global document, Office, module, require */

const title = "Fiscus AI Add-in";

const rootElement = document.getElementById("container");
const root = rootElement ? createRoot(rootElement) : undefined;

/* Render application after Office initializes */
Office.onReady(() => {
  root?.render(
    <FluentProvider theme={webLightTheme}>
      <App title={title} />
    </FluentProvider>
  );
});

if (module.hot) {
  module.hot.accept("./pages/App", () => {
    const NextApp = require("./pages/App").default;
    root?.render(<NextApp title={title} />);
  });
}
