import React, { useMemo } from "react";
import { DetailsList, DetailsListLayoutMode, SelectionMode, TextField, Text } from "@fluentui/react";
import { makeStyles } from "@fluentui/react-components";

const useStyles = makeStyles({
  root: {
    padding: "20px",
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    marginBottom: "24px",
  },
  header: {
    fontSize: "24px",
    fontWeight: "700",
    marginBottom: "16px",
  },
  searchBox: {
    marginBottom: "16px",
  },
  table: {
    marginTop: "16px",
  },
});

function Teams() {
  const styles = useStyles();

  const columns = useMemo(
    () => [
      {
        key: "column1",
        name: "Team Name",
        fieldName: "name",
        minWidth: 200,
        maxWidth: 300,
        isResizable: true,
      },
      {
        key: "column2",
        name: "Role",
        fieldName: "role",
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: "column3",
        name: "Members",
        fieldName: "members",
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
      },
    ],
    []
  );

  const items = useMemo(
    () => [
      {
        name: "Finance Team",
        role: "Manager",
        members: "15 Members",
      },
      {
        name: "Audit Team",
        role: "Associate",
        members: "8 Members",
      },
      {
        name: "Tax Team",
        role: "Admin",
        members: "10 Members",
      },
    ],
    []
  );

  return (
    <div className={styles.root}>
      <Text variant="xLarge" className={styles.header}>
        Teams
      </Text>

      <TextField placeholder="Search teams..." className={styles.searchBox} />

      <DetailsList
        items={items}
        columns={columns}
        setKey="set"
        layoutMode={DetailsListLayoutMode.justified}
        selectionMode={SelectionMode.none}
        className={styles.table}
      />
    </div>
  );
}

export default Teams;
