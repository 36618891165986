import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, Combobox, Option, SearchBox } from "@fluentui/react-components";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: "8px",
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    border: "1px solid #e0e0e0", // Light grey border
    marginBottom: "4px",
    gap: "8px",
    maxWidth: "400px",
  },
});

function GenericSearchAndFilter({
  searchQuery,
  onSearchChange,
  filters,
  onFilterChange,
  placeholder = "Search...",
  filterOptions = [],
}) {
  const styles = useStyles();
  const [selectedOptions, setSelectedOptions] = useState(Object.values(filters));

  useEffect(() => {
    setSelectedOptions(Object.values(filters).filter((filter) => filter !== "All"));
  }, [filters]);

  const handleSearchChange = (event, data) => {
    onSearchChange(data.value || "");
  };

  const handleFilterChange = (event, data) => {
    const updatedFilters = { ...filters };

    filterOptions.forEach((filter) => {
      if (filter.value === data.optionValue) {
        updatedFilters[filter.key] = data.selectedOptions.includes(filter.value) ? filter.value : "All";
      }
    });

    setSelectedOptions(Object.values(updatedFilters).filter((filter) => filter !== "All"));
    onFilterChange(event, updatedFilters);
  };

  return (
    <div className={styles.container}>
      <SearchBox placeholder={placeholder} value={searchQuery || ""} onChange={handleSearchChange} />
      <Combobox
        multiselect
        placeholder={selectedOptions.length > 0 ? selectedOptions.join(", ") : "Select filters"}
        selectedOptions={selectedOptions}
        onOptionSelect={handleFilterChange}
      >
        {filterOptions.map((filter) => (
          <Option key={filter.id} value={filter.value}>
            {filter.label}
          </Option>
        ))}
      </Combobox>
    </div>
  );
}

GenericSearchAndFilter.propTypes = {
  searchQuery: PropTypes.string.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  filterOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
};

export default GenericSearchAndFilter;
