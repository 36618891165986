const mockData = [
  {
    id: 1,
    client: {
      id: 1,
      name: "Jane Doe",
      email: "jane.doe@example.com",
      company: "Acme Inc.",
    },
    summary: "Tax filing assistance",
    dateTime: "2024-08-23T14:15:00Z",
    status: "Pending",
    steps: [
      {
        id: 101,
        name: "Gather Client Financial Documents",
        status: "Completed",
        details: [
          {
            id: 201,
            description: "Collected W-2 and 1099 forms from client.",
            time: "45 minutes ago",
            link: null,
            visible: true, // All steps in "Completed" are fully visible
          },
          {
            id: 202,
            description: "Retrieved statements from the client's brokerage accounts.",
            time: "40 minutes ago",
            link: { url: "#0", text: "View Document" },
            visible: true, // All steps in "Completed" are fully visible
          },
        ],
      },
      {
        id: 102,
        name: "Analyze Income Sources",
        status: "In Progress",
        currentStepIndex: 1, // The index of the current step being processed
        nextStepId: 205, // The ID for the next step to be added dynamically
        details: [
          {
            id: 203,
            description: "Calculated total taxable income from W-2.",
            time: "30 minutes ago",
            link: null,
            visible: true, // First detail of "In Progress" is visible
          },
          {
            id: 204,
            description: "Reviewed 1099 forms for dividend and interest income.",
            time: "25 minutes ago",
            link: { url: "#0", text: "View Report" },
            visible: false, // Hidden initially, revealed later
          },
        ],
      },
    ],
  },
  {
    id: 2,
    client: {
      id: 2,
      name: "John Doe",
      email: "john.doe@example.com",
      company: "Doe Industries",
    },
    summary: "Financial statement update",
    dateTime: "2024-08-22T11:30:00Z",
    status: "Completed",
    steps: [
      {
        id: 103,
        name: "Prepare Financial Statements",
        status: "Completed",
        details: [
          {
            id: 205,
            description: "Updated the balance sheet and income statement.",
            time: "2 hours ago",
            link: { url: "#1", text: "View Statement" },
            visible: true, // All steps in "Completed" are fully visible
          },
          {
            id: 206,
            description: "Reviewed financial ratios and metrics.",
            time: "1 hour ago",
            link: null,
            visible: true, // All steps in "Completed" are fully visible
          },
        ],
      },
    ],
  },
  {
    id: 3,
    client: {
      id: 3,
      name: "Dustin Allen",
      email: "dustin@fiscusflows.com",
      company: "Fiscus",
    },
    summary: "Investment Strategy Consultation",
    dateTime: "2024-08-21T09:45:00Z",
    status: "In Progress",
    steps: [
      {
        id: 104,
        name: "Analyze Current Investments",
        status: "In Progress",
        currentStepIndex: 0,
        nextStepId: 210,
        details: [
          {
            id: 207,
            description: "Reviewed portfolio performance over the last year.",
            time: "Now",
            link: { url: "#2", text: "View Portfolio" },
            visible: true, // First detail of "In Progress" is visible
          },
          {
            id: 208,
            description: "Identified high-risk investments for potential divestment.",
            time: "Now",
            link: null,
            visible: false, // Hidden initially, revealed later
          },
          {
            id: 209,
            description: "Assessed tax implications of potential portfolio changes.",
            time: "Now",
            link: null,
            visible: false, // Hidden initially, revealed later
          },
        ],
      },
      {
        id: 105,
        name: "Recommend Adjustments",
        status: "Pending",
        details: [
          {
            id: 210,
            description: "Drafting recommendations for portfolio adjustments.",
            time: "Now",
            link: null,
            visible: false, // Hidden initially, revealed later
          },
          {
            id: 211,
            description: "Preparing client report with detailed strategy suggestions.",
            time: "Now",
            link: { url: "#3", text: "View Draft" },
            visible: false, // Hidden initially, revealed later
          },
        ],
      },
      {
        id: 106,
        name: "Review and Finalize Strategy",
        status: "Pending",
        details: [
          {
            id: 212,
            description: "Presenting the finalized strategy to the client.",
            time: "Now",
            link: null,
            visible: false, // Hidden initially, revealed later
          },
          {
            id: 213,
            description: "Adjusting the strategy based on client feedback.",
            time: "Now",
            link: null,
            visible: false, // Hidden initially, revealed later
          },
          {
            id: 214,
            description: "Finalizing and documenting the investment strategy.",
            time: "Now",
            link: { url: "#4", text: "View Final Strategy" },
            visible: false, // Hidden initially, revealed later
          },
        ],
      },
    ],
  },
];

export default mockData;
