import React from "react";
import { makeStyles } from "@fluentui/react-components";
import AppRoutes from "./Routes";

const useStyles = makeStyles({
  root: {
    minHeight: "100vh",
    backgroundColor: "#ffffff",
    fontFamily: "Segoe UI, Tahoma, Geneva, Verdana, sans-serif",
  },
});

function App() {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <AppRoutes />
    </div>
  );
}

export default App;
