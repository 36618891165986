import React, { useState } from "react";
import PropTypes from "prop-types";
import { Text, Button, Modal, SearchBox } from "@fluentui/react";
import { makeStyles, Dropdown } from "@fluentui/react-components";
import { useNavigate } from "react-router-dom"; // Import navigation hook
import { formatDistanceToNow } from "date-fns";

const useStyles = makeStyles({
  card: {
    backgroundColor: "#ffffff", // White background for the card
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    border: "1px solid #e0e0e0", // Light grey border
    padding: "12px 16px",
    margin: "8px 0",
    cursor: "pointer",
    transition: "transform 0.2s, box-shadow 0.2s",
    "&:hover": {
      transform: "translateY(-2px)",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.15)",
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minWidth: "200px",
  },
  cardHeader: {
    fontSize: "16px",
    fontWeight: "600",
    color: "#333",
    marginBottom: "8px",
  },
  divider: {
    borderTop: "1px solid #ddd",
    margin: "8px 0",
  },
  contactName: {
    fontSize: "14px",
    color: "#333",
    marginBottom: "4px",
  },
  contactEmail: {
    fontSize: "14px",
    color: "#0078d4",
    marginBottom: "12px",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  cardFooter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "12px",
    color: "#666",
  },
  taskCount: {
    color: "#999",
  },
  modalContent: {
    padding: "16px",
    maxHeight: "80vh", // Limit the height for large lists of tasks
    overflowY: "auto", // Scrollable area for tasks
    display: "flex",
    flexDirection: "column",
    gap: "16px", // More spacing between elements
  },
  taskItem: {
    padding: "12px 16px",
    borderBottom: "1px solid #ddd",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    "&:hover": {
      // backgroundColor: "#f3f3f3",
    },
  },
  taskStatus: {
    fontSize: "12px",
    color: "#666",
    marginTop: "4px",
  },
  filterContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "16px",
  },
  searchContainer: {
    flexGrow: 1,
    marginRight: "8px",
  },
  dropdownContainer: {
    minWidth: "160px",
  },
});

function ContactsCard({ name, email, company, taskCount, tasks }) {
  const styles = useStyles();
  const navigate = useNavigate();
  const [isModalOpen, setModalOpen] = useState(false);
  const [filteredTasks, setFilteredTasks] = useState(tasks);
  const [searchQuery, setSearchQuery] = useState("");
  const [statusFilter, setStatusFilter] = useState("All");

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => {
    setModalOpen(false);
    setSearchQuery("");
    setStatusFilter("All");
    setFilteredTasks(tasks); // Reset filters on close
  };

  const handleTaskClick = (taskId) => {
    navigate(`/request/${taskId}`);
    setModalOpen(false); // Close the modal when a task is selected
  };

  const handleSearchChange = (event, newValue) => {
    const query = newValue || "";
    setSearchQuery(query);
    applyFilters(query, statusFilter);
  };

  const handleStatusFilterChange = (event, option) => {
    const status = option.key;
    setStatusFilter(status);
    applyFilters(searchQuery, status);
  };

  const applyFilters = (query, status) => {
    let updatedTasks = [...tasks];

    if (query) {
      updatedTasks = updatedTasks.filter((task) => task.summary.toLowerCase().includes(query.toLowerCase()));
    }

    if (status !== "All") {
      updatedTasks = updatedTasks.filter((task) => task.status === status);
    }

    setFilteredTasks(updatedTasks);
  };

  const statusOptions = [
    { key: "All", text: "All" },
    { key: "Pending", text: "Pending" },
    { key: "In Progress", text: "In Progress" },
    { key: "Completed", text: "Completed" },
  ];

  return (
    <div className={styles.card}>
      <Text className={styles.cardHeader}>{name}</Text>
      <div className={styles.divider}></div>
      <Text className={styles.contactName}>{company}</Text>
      <a href={`mailto:${email}`} className={styles.contactEmail}>
        {email}
      </a>
      <div className={styles.cardFooter}>
        <Button onClick={handleModalOpen} text={`View Tasks (${taskCount})`} />
        <Modal isOpen={isModalOpen} onDismiss={handleModalClose} isBlocking={false}>
          <div className={styles.modalContent}>
            <Text variant="xLarge">{`Tasks for ${name}`}</Text>
            <div className={styles.filterContainer}>
              <SearchBox
                className={styles.searchContainer}
                placeholder="Search tasks..."
                value={searchQuery}
                onChange={(e, newValue) => handleSearchChange(e, newValue)}
              />
              <Dropdown
                className={styles.dropdownContainer}
                placeholder="Filter by status"
                selectedKey={statusFilter}
                options={statusOptions}
                onChange={handleStatusFilterChange}
              />
            </div>
            {filteredTasks.map((task) => (
              <div key={task.id} className={styles.taskItem} onClick={() => handleTaskClick(task.id)}>
                <Text>{task.summary}</Text>
                <Text className={styles.taskStatus}>
                  {task.status} - {formatDistanceToNow(new Date(task.dateTime), { addSuffix: true })}
                </Text>
              </div>
            ))}
          </div>
        </Modal>
      </div>
    </div>
  );
}

ContactsCard.propTypes = {
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  taskCount: PropTypes.number.isRequired,
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      summary: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      dateTime: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default ContactsCard;
