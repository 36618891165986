import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import RequestsSearchFilter from "../components/RequestsSearchFilter";
import RequestsTable from "../components/RequestsTable";
import mockData from "../mockData"; // Updated import for single object
import { makeStyles } from "@fluentui/react-components";

const useStyles = makeStyles({
  container: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    padding: "0 16px",
  },
  content: {
    flexGrow: 1,
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
});

function Requests() {
  const styles = useStyles();
  const [searchQuery, setSearchQuery] = useState("");
  const [statusFilter, setStatusFilter] = useState("All");
  const [timeFilter, setTimeFilter] = useState("Last 7 Days");
  const [filteredItems, setFilteredItems] = useState([]);

  useEffect(() => {
    setFilteredItems(mockData);
  }, []);

  useEffect(() => {
    let updatedItems = [...mockData];

    if (searchQuery) {
      const lowercasedQuery = searchQuery.toLowerCase();
      updatedItems = updatedItems.filter(
        (item) =>
          item.client.name.toLowerCase().includes(lowercasedQuery) ||
          item.summary.toLowerCase().includes(lowercasedQuery) ||
          item.client.email.toLowerCase().includes(lowercasedQuery)
      );
    }

    if (statusFilter !== "All") {
      updatedItems = updatedItems.filter((item) => item.status === statusFilter);
    }

    if (timeFilter !== "All Time") {
      const now = new Date();
      updatedItems = updatedItems.filter((item) => {
        const itemDate = new Date(item.dateTime);
        switch (timeFilter) {
          case "Last 7 Days":
            return itemDate >= new Date(now.setDate(now.getDate() - 7));
          case "Last 30 Days":
            return itemDate >= new Date(now.setMonth(now.getMonth() - 1));
          case "This Year":
            return itemDate.getFullYear() === now.getFullYear();
          default:
            return true;
        }
      });
    }

    setFilteredItems(updatedItems);
  }, [searchQuery, statusFilter, timeFilter]);

  return (
    <div className={styles.container}>
      <Header showBackButton={true} />
      <div className={styles.content}>
        <RequestsSearchFilter
          searchQuery={searchQuery}
          onSearchChange={(value) => setSearchQuery(value || "")}
          statusFilter={statusFilter}
          onStatusChange={(e, option) => setStatusFilter(option.key)}
          timeFilter={timeFilter}
          onTimeChange={(e, option) => setTimeFilter(option.key)}
        />
        <RequestsTable data={filteredItems} />
      </div>
    </div>
  );
}

export default Requests;
