import React from "react";
import { Dropdown, DefaultButton } from "@fluentui/react";
import { makeStyles } from "@fluentui/react-components";
import Card from "../components/Card"; // Import the reusable Card component
import Header from "../components/Header";
import { SettingsIcon } from "@fluentui/react-icons-mdl2";

const useStyles = makeStyles({
  root: {
    padding: "0 20px",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  cardContent: {
    marginTop: "20px",
    width: "100%",
  },
  dropdown: {
    marginBottom: "12px",
  },
  button: {
    backgroundColor: "#0078d4",
    color: "#ffffff",
    width: "100%",
    padding: "12px 0",
    borderRadius: "8px",
  },
});

function GeneralSettings() {
  const styles = useStyles();

  const themeOptions = [
    { key: "dark", text: "Dark Mode" },
    { key: "light", text: "Light Mode" },
  ];

  const notificationOptions = [
    { key: "on", text: "On" },
    { key: "off", text: "Off" },
  ];

  return (
    <div className={styles.root}>
      <Header showBackButton={true} />
      <Card
        icon={<SettingsIcon />}
        title="General Settings"
        description={
          <div className={styles.cardContent}>
            <Dropdown label="Theme" options={themeOptions} placeholder="Select a theme" className={styles.dropdown} />
            <Dropdown
              label="Email Notifications"
              options={notificationOptions}
              placeholder="Enable/Disable"
              className={styles.dropdown}
            />
            <DefaultButton text="Save Changes" className={styles.button} />
          </div>
        }
        onClick={() => {}}
      />
    </div>
  );
}

export default GeneralSettings;
