import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "@fluentui/react-components";
// import { useNavigate } from "react-router-dom";
import RequestsCard from "./RequestsCard"; // Import the new RequestsCard component
import { makeStyles } from "@fluentui/react-components";

const useStyles = makeStyles({
  tableContainer: {
    height: "100%", // Take full available height
    overflowY: "auto", // Allow vertical scrolling within the container
    padding: "10px 0", // Padding for better visual separation
  },
  cardContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
    gap: "16px",
    padding: "8px",
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "16px",
  },
  paginationButton: {
    margin: "0 8px",
  },
});

function RequestsTable({ data }) {
  const styles = useStyles();
  // const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 6; // Number of cards per page, adjust as needed
  const totalPages = Math.ceil(data.length / pageSize);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const paginatedData = data.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  return (
    <div className={styles.tableContainer}>
      <div className={styles.cardContainer}>
        {paginatedData.map((item) => (
          <RequestsCard
            key={item.id}
            id={item.id}
            summary={item.summary}
            client={item.client.name}
            email={item.client.email}
            date={item.dateTime}
            status={item.status} // Pass the status to the RequestsCard
          />
        ))}
      </div>
      <div className={styles.paginationContainer}>
        <Button className={styles.paginationButton} onClick={handlePreviousPage} disabled={currentPage === 1}>
          Previous
        </Button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <Button className={styles.paginationButton} onClick={handleNextPage} disabled={currentPage === totalPages}>
          Next
        </Button>
      </div>
    </div>
  );
}

RequestsTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      client: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        company: PropTypes.string.isRequired,
      }).isRequired,
      summary: PropTypes.string.isRequired,
      dateTime: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      steps: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
          name: PropTypes.string.isRequired,
          status: PropTypes.string.isRequired,
          details: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
              description: PropTypes.string.isRequired,
              time: PropTypes.string,
              link: PropTypes.shape({
                url: PropTypes.string,
                text: PropTypes.string,
              }),
            })
          ).isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
};

export default RequestsTable;
