/* global Office console setInterval, clearInterval */

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Header from "../components/Header";
import RequestsCard from "../components/RequestsCard";
import StepsTaken from "../components/StepsTaken";
import AIInstructionsPopover from "../components/AIInstructionsPopover";
import { makeStyles } from "@fluentui/react-components";
import mockData from "../mockData"; // Initial mock data
// import { createDraft } from "../taskpane"; // Import the createDraft function

const useStyles = makeStyles({
  root: {
    position: "relative",
    padding: "0 16px",
    minHeight: "100vh",
    fontFamily: "Segoe UI, Tahoma, Geneva, Verdana, sans-serif",
    display: "flex",
    flexDirection: "column",
  },
  stepsContainer: {
    flex: 1,
    overflowY: "auto",
    marginBottom: "80px",
  },
});

function Request() {
  const styles = useStyles();
  const { id } = useParams();
  const [requestData, setRequestData] = useState(() => {
    const initialData = mockData.find((request) => request.id.toString() === id);
    return { ...initialData };
  });

  useEffect(() => {
    if (!requestData || requestData.status !== "In Progress") return;

    const intervalId = setInterval(() => {
      simulateApiUpdate(setRequestData, intervalId);
    }, 5000); // Simulate receiving an update every 5 seconds

    return () => clearInterval(intervalId);
  }, [requestData]);

  useEffect(() => {
    if (requestData?.status === "Completed") {
      draftCompletionEmail(requestData); // Create the draft email once the request is complete
    }
  }, [requestData]);

  if (!requestData) {
    return <div>Request not found.</div>;
  }

  const visibleSteps =
    requestData.status === "In Progress"
      ? requestData.steps.filter((step) => step.status !== "Pending")
      : requestData.steps;

  return (
    <div className={styles.root}>
      <Header showBackButton={true} />
      <RequestsCard
        id={requestData.id}
        summary={requestData.summary}
        client={requestData.client.company}
        email={requestData.client.email}
        date={requestData.dateTime}
        status={requestData.status} // Pass down the dynamic status
      />
      <div className={styles.stepsContainer}>
        <StepsTaken steps={visibleSteps} />
      </div>
      <AIInstructionsPopover onSubmit={handleInstructionSubmit} />
    </div>
  );
}

const handleInstructionSubmit = (instructions) => {
  console.log("Instructions submitted:", instructions);
};

// Function to draft the completion email
async function draftCompletionEmail(requestData) {
  try {
    await Office.onReady();
    if (Office.context.mailbox) {
      const subject = `Request Completed: ${requestData.summary}`;
      const to = [requestData.client.email];
      const body = `Hello ${requestData.client.name},\n\nYour request for "${requestData.summary}" has been successfully completed.\n\nBest regards,\nYour Team`;
      console.log(subject);
      console.log(to);
      console.log(body);
      // createDraft(subject, to, body);
    } else {
      console.error("Office context is not ready.");
    }
  } catch (error) {
    console.error("Error creating draft email:", error);
  }
}

// Simulate an API update by adding new steps or updating existing ones
function simulateApiUpdate(setRequestData, intervalId) {
  setRequestData((prevData) => {
    const newSteps = [...prevData.steps];

    // Find the first step that is still in progress
    const currentStepIndex = newSteps.findIndex((step) => step.status === "In Progress");
    if (currentStepIndex !== -1) {
      const currentStep = { ...newSteps[currentStepIndex] };
      const currentDetailIndex = currentStep.details.findIndex((detail) => !detail.visible);

      if (currentDetailIndex !== -1) {
        // Reveal the next detail in the current step
        currentStep.details[currentDetailIndex].visible = true;
        currentStep.details[currentDetailIndex].time = "Just now";
      } else {
        // Mark the current step as completed
        currentStep.status = "Completed";
        newSteps[currentStepIndex] = currentStep;

        if (currentStepIndex + 1 < newSteps.length) {
          // Start the next step if available
          newSteps[currentStepIndex + 1].status = "In Progress";
          newSteps[currentStepIndex + 1].details[0].visible = true;
        } else {
          // No more steps, mark the entire request as Completed
          clearInterval(intervalId);
          return {
            ...prevData,
            status: "Completed", // All steps completed, set to Completed
            steps: newSteps,
          };
        }
      }
      newSteps[currentStepIndex] = currentStep;
    }

    // If all steps are completed, transition the request status
    if (newSteps.every((step) => step.status === "Completed")) {
      clearInterval(intervalId); // Stop the interval when done
      return {
        ...prevData,
        status: "Completed", // All steps completed, set to Completed
        steps: newSteps,
      };
    }

    return {
      ...prevData,
      steps: newSteps,
    };
  });
}

export default Request;
